$spacingXS: 2px;
$spacingSmall: 4px;
$spacingMedium: 8px;
$spacingLarge: 16px;
$spacingXL: 24px;
$spacingXXL: 32px;
$spacingXXXL: 50px;
$navBarHeight: calc(#{$spacingXXXL} + env(safe-area-inset-top));
$tabsHeight: calc(#{$spacingXXXL} + env(safe-area-inset-bottom));



