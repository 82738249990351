@import '../variables.scss';

.rs-nav-bar {
  border-bottom: 1px solid #e1e0e0c9;
  height: $navBarHeight;
  box-sizing: border-box;
  padding: $spacingLarge;
  padding-top: calc(#{$spacingLarge} + env(safe-area-inset-top));
  transition: opacity .35s ease, left .35s ease, right .35s ease;
  opacity: 0;
  z-index: 30;
  position: absolute;
  left: 100%;
  right: -100%;
  bottom: 0;
  width: 100%;
  display: flex;

  .left,.right {
    width: 20%;
    height: 100%;
  }

  .center {
    width: 60%;
    height: 100%;
  }

  &:first-of-type {
    .chevron-left {
      display: none;
    }
  }

  .chevron-left {
    position: absolute;
    bottom: $spacingLarge;
    left: $spacingLarge;
    height: $spacingXL;
    width: $spacingXL;
    color: black;
  }

  &.nav-to-remove {
    opacity: 0;
    z-index: 20;
    right: -50%;
    left: 50%;
  }

  &.nav-current {
    opacity: 1;
    left: 0;
    right: 0;
  }

  &.nav-previous {
    opacity: 0;
    z-index: 20;
    right: 20%;
    left: -20%;
  }

  &.placeholder {
    height: 0;
    padding: 0;
    border: none;
  }
}