@import '../variables.scss';

.rs-popup-content {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 120vh;
	transition: top 0.5s ease;
	overflow: hidden;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;
}

.show .rs-popup-content {
	top: 0;
}

.rs-popup {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: transparent;

	&.hidden {
		display: none;
	}

	&.show {
		background-color: rgba(0, 0, 0, 0.18);
	}
	transition: background-color 0.5s ease;
	&:nth-last-of-type(6) {
		z-index: 95;
	}

	&:nth-last-of-type(5) {
		z-index: 96;
	}

	&:nth-last-of-type(4) {
		z-index: 97;
	}

	&:nth-last-of-type(3) {
		z-index: 98;
	}

	&:nth-last-of-type(2) {
		z-index: 99;
	}

	&:nth-last-of-type(1) {
		z-index: 100;
	}
}
