@import '../variables.scss';

.rs-view {
  height: 100vh;
  position: relative;
  display: none;
  overflow: hidden;

  .nav-parent {
    width: 100%;
    transition: height .35s ease;
    position: absolute;
    top: 0;
    overflow: hidden;
    height: $navBarHeight;
    z-index: 40;
    background: #ffffffDD;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  &.active-view {
    display: block;
  }
}

html {
  height: 100vh;
}

* {
}

