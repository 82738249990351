@import '../variables.scss';

.rs-tabs {
  position: absolute;
  bottom: 0;
  z-index: 20;
  width: 100%;
  height: $tabsHeight;
  box-sizing: border-box;
  padding-bottom: env(safe-area-inset-bottom);
  display: flex;
  justify-content: space-evenly;
}